import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    BoxProps,
    Flex,
    Grid,
    Show,
} from '@chakra-ui/react';
import React, { ReactNode, useMemo } from 'react';
import { useHistory } from 'react-router';
import { CBetaBadge } from '../Badges';
// eslint-disable-next-line import/no-cycle
import { CBackButton } from '../Buttons';
import { responsiveStyles } from '../Chakra.utils';
import { Breakpoints, ChakraSizes } from '../ChakraTheme';
import { Spacing } from '../ChakraTheme/configs/Spacing.config';
import { CHeader } from '../Typography';
import {
    MainSectionProps,
    MainWrapperPropsBase,
    RowProps,
} from './Layouts.type';

/** EXAMPLE USE
<CSection>
     <CRow>
        <CColumn></CColumn>
        <CColumn></CColumn>
    </CRow>
    <CRow>
        <CColumn></CColumn>
        <CColumn></CColumn>
        <CColumn></CColumn>
    </CRow>
 </CSection>
* */

export const CContentContainer = ({
    children,
    testId,
    title,
    isFullscreen = false,
    hasBetaFlag = false,
    hasBackButton = false,
    backRoute,
    bannerProps,
}: MainWrapperPropsBase & {
    isFullscreen?: boolean;
    hasBetaFlag?: boolean;
    hasBackButton?: boolean;
    backRoute?: string;
    bannerProps?: {
        title: string;
        description: string;
    };
}): JSX.Element => {
    const history = useHistory();
    return (
        <CColumn
            data-test={`${testId}--container`}
            pt={Spacing.MD}
            px={responsiveStyles(Spacing.SM, Spacing.MD)}
            maxW={isFullscreen ? undefined : '1920px'} // this is for some views with monitors bigger than 1920px
            maxH="100%"
            height="100%"
            gap={Spacing.MD}
        >
            {!!title && (
                <CRow gap={Spacing.SM} alignItems="center">
                    {hasBackButton && !!backRoute && (
                        <CBackButton
                            testId={`${testId}--back-button`}
                            onClick={() => history.push(backRoute)}
                        />
                    )}
                    <CHeader text={title} mb={0} />
                    {hasBetaFlag && <CBetaBadge />}
                    {!!bannerProps && (
                        <Alert
                            status="success"
                            backgroundColor="#FFF2FC"
                            color="#DB3AB5"
                            w="auto"
                            ml={Spacing.MD}
                        >
                            <AlertIcon color="#DB3AB5" />
                            <CColumn gap={0}>
                                <AlertTitle>{bannerProps?.title}</AlertTitle>
                                <AlertDescription>
                                    {bannerProps?.description}
                                </AlertDescription>
                            </CColumn>
                        </Alert>
                    )}
                </CRow>
            )}
            {children}
            {/* hack to add space to bottom of our pages with current overflow setup */}
            <Box minHeight={1} width="100%" />
        </CColumn>
    );
};

export const CSection = ({
    children,
    maxW = '100%',
    testId,
    ...props
}: MainSectionProps): JSX.Element => (
    <Flex
        gap={Spacing.SM}
        data-test={`${testId}--container`}
        justifyContent="start"
        alignItems="start"
        width="100%"
        /* @ts-ignore */
        maxW={{ base: '100%', md: maxW }}
        direction="column"
        {...props}
    >
        {children}
    </Flex>
);

export const CRow = ({
    children,
    maxW = '100%',
    reverseOnMobile,
    noBreak,
    breakPoint = ChakraSizes.md,
    ...props
}: RowProps): JSX.Element => (
    <Flex
        gap={Spacing.SM}
        width="100%"
        /* @ts-ignore */
        maxW={{ base: '100%', [breakPoint]: maxW }}
        justifyContent="flex-start"
        alignItems="flex-start"
        direction={
            noBreak
                ? 'row'
                : {
                      base: reverseOnMobile ? 'column-reverse' : 'column',
                      [breakPoint]: 'row',
                  }
        }
        {...props}
    >
        {children}
    </Flex>
);

export const CColumn = ({
    children,
    maxW = '100%',
    ...props
}: BoxProps): JSX.Element => (
    <Flex
        w="100%"
        gap={Spacing.SM}
        justifyContent="start"
        alignItems="stretch"
        {...props}
        /* @ts-ignore */
        maxW={{ base: '100%', md: maxW }}
        direction="column"
    >
        {children}
    </Flex>
);

export const CGroup = ({
    ...props
}: Omit<RowProps, 'noBreak'>): JSX.Element => (
    <CRow as="span" {...props} noBreak width="auto" />
);

export const CGrid = ({
    children,
    numberOfColumns = 4,
    ...props
}: { numberOfColumns?: 2 | 3 | 4 } & BoxProps): JSX.Element => {
    const templateColumnStyle = useMemo(() => {
        switch (numberOfColumns) {
            case 2:
                return {
                    base: 'repeat(1, minmax(auto, 1fr))',
                    lg: 'repeat(2, minmax(45%, 1fr))',
                };
            case 3:
                return {
                    base: 'repeat(1, minmax(auto, 1fr))',
                    md: 'repeat(2, minmax(45%, 1fr))',
                    lg: 'repeat(3, minmax(30%, 1fr))',
                };
            // default is four
            default:
                return {
                    base: 'repeat(1, minmax(auto, 1fr))',
                    md: 'repeat(2, minmax(45%, 1fr))',
                    lg: 'repeat(2, minmax(45%, 1fr))',
                    xl: 'repeat(4, minmax(23%, 1fr))',
                };
        }
    }, [numberOfColumns]);
    return (
        <Grid
            gap={Spacing.SM}
            width="100%"
            templateColumns={templateColumnStyle}
            {...props}
        >
            {children}
        </Grid>
    );
};

export const CDeskTopOnlyBox = ({ children }: BoxProps): JSX.Element => (
    <Show breakpoint={`(min-width: ${Breakpoints.md})`}>{children}</Show>
);

export const CShowOnMobile = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => (
    <Show breakpoint={`(max-width: ${Breakpoints.md})`}>{children}</Show>
);

/// Form layout Components
export const CFormFieldContainer = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => (
    <Flex
        width="100%"
        maxWidth="600px"
        justifyContent="start"
        alignItems="start"
        gap={Spacing.XS}
        direction="column"
    >
        {children}
    </Flex>
);

export const CFormRow = ({
    children,
    alignItems = 'start',
    ...props
}: RowProps): JSX.Element => (
    <CRow w="100%" gap={Spacing.XS} py={0} alignItems={alignItems} {...props}>
        {children}
    </CRow>
);

export const CFormColumn = ({ children, ...props }: BoxProps): JSX.Element => (
    <CColumn w="100%" gap={Spacing.XXS} {...props}>
        {children}
    </CColumn>
);

export const CForm = ({ children, ...props }: BoxProps): JSX.Element => (
    <CColumn as="form" w="100%" gap={0} {...props}>
        {children}
    </CColumn>
);
