import { faShare, faDownload } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import {
    Spacing,
    BorderWidth,
    ChakraSizes,
    Typography,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';

import { Spinner as CSpinner } from '@chakra-ui/react';
import { useCToast } from '../Toast';
import { ButtonBaseProps } from './Button.type';
import {
    CBorderButton,
    CErrorButton,
    CSuccessButton,
} from './Buttons.component';
import { CFAIcon, CMinusIcon, CPlusIcon } from '../Icons';
import { CBoldText, CText } from '../Typography';
// eslint-disable-next-line import/no-cycle
import { CRow } from '../Layouts/Layouts.component';

export const CShareUrlButton = ({ ...props }: ButtonBaseProps): JSX.Element => {
    const { showSuccessToast } = useCToast();

    const copy = async () => {
        await navigator.clipboard.writeText(window.location.href);
        showSuccessToast('Link copied to clipboard');
    };
    return (
        <CBorderButton
            onClick={copy}
            {...props}
            minW="100px"
            border={`${BorderWidth.Thinnest} solid`}
            testId="copy-button"
        >
            <CRow
                gap={Spacing.XS}
                pr={Spacing.SM}
                pl={Spacing.XS}
                alignItems="center"
            >
                <CFAIcon icon={faShare} />
                <CText text="Share" fontWeight={Typography.fontWeights.bold} />
            </CRow>
        </CBorderButton>
    );
};

export const CDownloadCSVButton = ({
    isLoading = false,
    ...props
}: ButtonBaseProps): JSX.Element => {
    return (
        <CRow w="auto" alignItems="center">
            {isLoading && <CSpinner />}
            <CBorderButton
                minW="185px"
                {...props}
                border={`${BorderWidth.Thinnest} solid`}
                testId="download-csv-button"
            >
                <CRow
                    gap={Spacing.XS}
                    pr={Spacing.SM}
                    pl={Spacing.XS}
                    alignItems="center"
                >
                    <CFAIcon icon={faDownload} />
                    <CBoldText
                        as="span"
                        text={isLoading ? 'Downloading...' : 'Download CSV'}
                        fontWeight={Typography.fontWeights.bold}
                    />
                </CRow>
            </CBorderButton>
        </CRow>
    );
};

export const CAddButton = ({
    hideText = false,
    ...props
}: ButtonBaseProps & { hideText?: boolean }): JSX.Element => (
    <CSuccessButton
        {...props}
        py={Spacing.XS}
        px={Spacing.SM}
        size={ChakraSizes.sm}
    >
        <CRow gap={Spacing.XS} noBreak alignItems="center">
            {!hideText && 'Add'}
            <CPlusIcon height="8px" />
        </CRow>
    </CSuccessButton>
);

export const CRemoveButton = ({
    hideText = false,
    ...props
}: ButtonBaseProps & { hideText?: boolean }): JSX.Element => (
    <CErrorButton
        {...props}
        py={Spacing.XS}
        px={Spacing.SM}
        size={ChakraSizes.sm}
    >
        <CRow gap={Spacing.XS} noBreak alignItems="center">
            {!hideText && 'Remove'}
            <CMinusIcon height="8px" />
        </CRow>
    </CErrorButton>
);
